@import url(https://fonts.googleapis.com/css?family=Lato:400,600,700);
/* Styles go here */

.navbar {
  background: linear-gradient(90deg, #700877 0%, #ff2759 100%, #ff2759 100%);
  opacity: 1;
}




.navbar-nav small {
  display: inline-block;
  margin-top: 11px;
  margin-right: 10px;
}

.switcher {
  margin-top: 70px;
}


.switch-button {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  text-overflow: clip;
  letter-spacing: 3px;
  background: rgba(0, 0, 0, 1);
  border: 1x solid rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 0.9);
  background: #ffffff;
  /* -webkit-box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.74);
  box-shadow: 7px 7px 0 0 rgba(0, 0, 0, 0.74);
  text-shadow: -1px -1px 0 rgba(15, 73, 168, 0.66); */
  transition: none;
  float: left;
  margin-right: 5px;
  outline: none !important;
}

.switch-button.active {
  display: inline-block;
  cursor: pointer;
  padding: 11px 20px;
  color: rgba(255, 255, 255);
  text-overflow: clip;
  letter-spacing: 3px;
  background: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  background: black;
  float: left;
  margin-right: 5px;
}



.switch-button:active {
  color: rgba(0, 0, 0, 0.9);
  background: #ffffff;
  transition: none;
  border: 1px solid rgba(0, 0, 0, 1);
}


/*
Cards start
*/
.snip1527 {
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  float: left;
  font-family: 'Lato', Arial, sans-serif;
  font-size: 16px;
  margin: 10px 1%;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  padding-left: 0;
  z-index: 0;
  padding-right: 0;
}

.snip1527 * {
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.snip1527 img {
  max-width: 100%;
  vertical-align: top;
  position: relative;
}

.snip1527 .image {
  width: 100%;
  height: 251px;
  background-size: cover;
}

.snip1527 figcaption {
  padding: 25px 20px 25px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  width: 100%;
}

.snip1527 figcaption:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #700877;
  content: '';
  background: linear-gradient(90deg, #700877 0%, #ff2759 100%, #ff2759 100%);
  opacity: 0.8;
  z-index: -1;
}

.snip1527 .date {
  background-color: #fff;
  color: #700877;
  font-size: 8px;
  font-weight: 700;
  padding: 4px 0;
  position: absolute;
  right: 15px;
  text-align: center;
  top: 0;
  width: 100%;
  left: 0;
}

.snip1527 .date span {
  display: block;
  line-height: 14px;
}

.snip1527 .date .month {
  font-size: 11px;
}

.snip1527 h3,
.snip1527 p {
  margin: 0;
  padding: 0;
}

.snip1527 h3 {
  display: inline-block;
  font-weight: 700;
  letter-spacing: -0.4px;
  margin-bottom: 5px;
  font-size: 24px;
}

.snip1527 p {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0px;
}

.snip1527 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.snip1527:hover img,
.snip1527.hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*
Cards stop
*/




/*
Loader start
*/

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 9999999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*
Loader stop
*/

/*
React modal start
*/

.ReactModal__Content--after-open {
  z-index: 9999999;
}

.ReactModal__Overlay {
  background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
  z-index: 9999;
}

.modelContent {
  font-family: 'Lato', Arial, sans-serif;
}

.modelContent img {
  max-height: 300px;
  margin-top: 25px;
}

.modelContent p {
  margin-top: 15px;
  font-size: 17px;
}

.modelContent .column {
  float: left;
}

.modelContent .buttons-row {
  margin-top: 15px;
}


.snip1527 {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  float: left;
  font-family: 'Lato', Arial, sans-serif;
  font-size: 16px;
  margin: 10px 1%;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  padding-left: 0;
  z-index: 0;
  padding-right: 0;
}

.close-button {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  color: rgba(255, 255, 255);
  text-overflow: clip;
  letter-spacing: 3px;
  background: rgba(0, 0, 0, 1);
  border: 0px solid transparent;
}

.close-button {
  background: black;
  float: right;
}

.modal-close-button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 12px;
  padding: 10px;
}



.close-button:active {
  color: rgba(0, 0, 0, 0.9);
  background: #ffffff;
  transition: none;
}

.transfer-button {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  color: rgba(255, 255, 255);
  text-overflow: clip;
  letter-spacing: 3px;
  background: rgba(0, 0, 0, 1);
  border: 0px solid transparent;
  margin-right: 10px;
}

.transfer-button {
  background: #ff2759;
  float: left;
}



.transfer-button:active {
  color: rgba(0, 0, 0, 0.9);
  background: #ffffff;
  transition: none;
}


.mintform-holder input {
  margin-top: 15px;
}

.mintform-holder form {
  margin-top: 45px;
}

.black-button {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  color: rgba(255, 255, 255);
  text-overflow: clip;
  letter-spacing: 3px;
  background: rgba(0, 0, 0, 1);
  border: 0px solid transparent;
}

.black-button:active {
  color: rgba(0, 0, 0, 0.9);
  background: #ffffff;
  transition: none;
}


/*
React modal stop
*/


/*
Transfer form start
*/

.transferForm .btn {
  margin-top: 15px;
}

.transferForm .title {
  display: inline-block;
  width: 100%;
  font-size: 19px;
  margin-top: 15px;
}

.transferForm .select {
  width: 100%;
  min-width: 15ch;
  border: 1px solid black;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  margin-top: 15px;
}

.transferForm .black-button {
  margin-top: 15px;
}
/*
Transfer form stop
*/





/*
Image uploader start
*/

.image-item{
  width: 300px;
  margin-bottom: 15px;
  display: inline-block;
}

.image-item img{
  width: 100%;
}

.image-item .image-item__btn-wrapper{

}
.image-item .image-item__btn-wrapper button{
  width: 45%;
  float: left;
  margin-top: 5px;
  margin-left: 10px;
}

.upload__image-wrapper {
  margin-top: 45px;
}
/*
Image uploader stop
*/



/*
Mobile queries start
*/
@media only screen and (min-width: 300px) and (max-width: 500px) { 
  nav button {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    font-size: 11px;
  }
  .switcher .switch-button {
    width: 48%;
    font-size: 10px;
    padding: 7px 12px;
  }
  .modelContent h2 {
    font-size: 20px;
  }

  .modelContent p, .modelContent .owner {
    font-size: 12px;
    overflow: hidden;
  }

  .modelContent button {
    font-size: 12px;
    float: left;
  }

  .modelContent .ownership-column {
    margin-top: 15px;
    overflow: scroll;
  }

  .modelContent .transfer-column {
    padding: 10px;
    background: #d8d8d8;
    margin-top: 20px;
  }

  .switch-button.active {
    padding: 8px 12px!important;
  }

  .upload__image-wrapper {
    max-width: 255px;
  }

  .upload__image-wrapper .image-item {
    width: 100%;
  }

  table {
    font-size: 12px;
  }
} 

/*
Mobile queries end
*/


